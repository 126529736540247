import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { ShopifyProduct, ShopifyProductStatus } from '../../../store/shopifyProductsSlice';
import { Space, Tag, Tooltip, Image, Skeleton } from 'antd';
import { statusColorsProduct } from '../../../constants/statusColors';

interface ColumnsProps {
    getColumnSearchProps: (dataIndex: string, customRender?: any) => any;
    handleShopifyProductDetailModalOpen: (shopifyProduct: ShopifyProduct) => void;
    handleImageCountClicked: (shopifyProduct: ShopifyProduct) => void;
    tagFilters: { text: string; value: string }[];
    productTypeFilters: { text: string; value: string }[];
    hasDescriptionFilters: { text: string; value: boolean }[];
    statusFilters: { text: string; value: ShopifyProductStatus }[];
}

export const getColumns = ({
    getColumnSearchProps,
    handleShopifyProductDetailModalOpen,
    handleImageCountClicked,
    tagFilters,
    productTypeFilters,
    hasDescriptionFilters,
    statusFilters
}: ColumnsProps): ColumnsType<ShopifyProduct> => [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 70,
            render: (image) => (
                <div style={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                    {
                        image ? (
                            <Image
                                width={40}
                                height={40}
                                src={image}
                                fallback="https://via.placeholder.com/40" // Placeholder image if loading fails
                                style={{ objectFit: 'cover' }}
                            />
                        ) : (
                            <Skeleton.Avatar active size="small" shape="square" />
                        )}
                </div>
            ),
            fixed: 'left'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 250,
            fixed: 'left',
            ...getColumnSearchProps(
                'title',
                (highlightedText: string, shopifyProduct: ShopifyProduct) => (
                    <Tooltip title={shopifyProduct.title} >
                        <a
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent row-level event conflicts
                                handleShopifyProductDetailModalOpen(shopifyProduct);
                            }}
                        >
                            {highlightedText}
                        </a>
                    </Tooltip>
                )
            ),
        },
        {
            title: 'Has Description',
            dataIndex: 'bodyHtml',
            key: 'bodyHtml',
            render: (bodyHtml, record) => (
                <Tag key={record.id + '_has_desciption'} > {bodyHtml ? "Yes" : "No"} </Tag>
            ),
            filters: hasDescriptionFilters,
            // onFilter: (value, record) => !!record.bodyHtml === value,
            width: 120,
        },
        {
            title: 'Product Type',
            dataIndex: 'productType',
            key: 'productType',
            filters: productTypeFilters,
            filterMultiple: false,
            // onFilter: (value, record) => {
            //     const tags = record.shopifyTags ?? [];
            //     return tags.includes(value as string);

            // }
        },
        {
            title: 'Tags',
            dataIndex: 'shopifyTags',
            key: 'shopifyTags',
            render: (shopifyTags) => (
                <Space size="small" >
                    {
                        shopifyTags.map((t: string, index: string) => (
                            <Tag key={index} > {t} </Tag>
                        ))
                    }
                </Space>
            ),
            filters: tagFilters,
            // onFilter: (value, record) => {
            //     const tags = record.shopifyTags ?? [];
            //     return tags.includes(value as string);

            // }
        },
        {
            title: 'Images',
            dataIndex: 'imageCount',
            key: 'imageCount',
            width: 100,
            sorter: (a, b) => a.imageCount - b.imageCount, // Numeric sorting
            render: (imageCount, shopifyProduct) => <a onClick={() => handleImageCountClicked(shopifyProduct)}> {imageCount} </a>
        },
        {
            title: 'Variants',
            dataIndex: 'variantCount',
            key: 'variantCount',
            width: 100,
            sorter: (a, b) => a.variantCount - b.variantCount, // Numeric sorting
        },
        {
            title: 'Shopify Status',
            dataIndex: 'shopifyStatus',
            key: 'shopifyStatus',
            width: 150,
            sorter: (a, b) => a.shopifyStatus.localeCompare(b.shopifyStatus), // Alphabetical sorting
            // TODO: add filter
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            filters: statusFilters,
            filterMultiple: false,
            // onFilter: (value, record) => record.status === value,
            sorter: (a, b) => a.status.localeCompare(b.status), // Alphabetical sorting
            render: (status: ShopifyProductStatus) => (
                <Tag color={statusColorsProduct[status]} >
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            )
        }
    ];