import React from 'react';
import { Space, Button, Dropdown, Tooltip, Row } from 'antd';
import { BarChartOutlined, DownloadOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';

interface TableActionsProps {
    selectedRowKeys: React.Key[];
    setShopifyUploadApprovedContentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleAnalyzeShopifyProducts: () => void;
    handleReloadShopifyProducts: () => void;
}

const TableActions: React.FC<TableActionsProps> = ({ selectedRowKeys, setShopifyUploadApprovedContentModalOpen, handleAnalyzeShopifyProducts, handleReloadShopifyProducts }) => {

    const handleMenuClick = (action: string, type: React.Key) => {
        if (action === 'download') {
            if (type === 'all') {
                console.log('Download All');
                // Implement download all logic
            } else if (type === 'selected') {
                console.log('Download Selected');
                // Implement download selected logic
            }
        } else if (action === 'analyze') {
            if (type === 'all') {
                console.log('Analyze All');
                // Implement analyze all logic
            } else if (type === 'selected') {
                console.log('Analyze Selected');
                // Implement analyze selected logic
            }
        }
    };

    const downloadMenu: {
        items: Array<{ key: React.Key; label: string; disabled?: boolean }>;
        onClick: ({ key }: { key: React.Key }) => void;
    } = {
        items: [
            { key: 'all', label: 'Download All' },
            { key: 'selected', label: `Download Selected (${selectedRowKeys.length})`, disabled: selectedRowKeys.length === 0 },
        ],
        onClick: ({ key }) => handleMenuClick('download', key),
    };

    const analyzeMenu: {
        items: Array<{ key: React.Key; label: string; disabled?: boolean }>;
        onClick: ({ key }: { key: React.Key }) => void;
    } = {
        items: [
            { key: 'all', label: 'Analyze All' },
            { key: 'selected', label: `Analyze Selected (${selectedRowKeys.length})`, disabled: selectedRowKeys.length === 0 },
        ],
        onClick: ({ key }) => handleMenuClick('analyze', key),
    };

    return (
        <Row justify="end">
            <Space size="small">
                <Tooltip title="Upload Approved Content">
                    <Button
                        type="default"
                        icon={<UploadOutlined />}
                        onClick={() => setShopifyUploadApprovedContentModalOpen(true)}
                    />
                </Tooltip>
                <Tooltip title="Download">
                    <Dropdown menu={downloadMenu} trigger={['click']} aria-label="Download Options" >
                        <Button
                            disabled
                            type="default"
                            icon={<DownloadOutlined />}
                        />
                    </Dropdown>
                </Tooltip>
                <Tooltip title="Re-Analyze all">
                    <Button
                        type="primary"
                        icon={<BarChartOutlined />}
                        onClick={handleAnalyzeShopifyProducts}
                    />
                </Tooltip>
                <Tooltip title="Refresh">
                    <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={handleReloadShopifyProducts}
                    />
                </Tooltip>
                {/* TODO: Make this selectable again */}
                {/* <Tooltip title="Analyze">
                                <Dropdown menu={analyzeMenu} trigger={['click']} aria-label="Analyze Options">
                                    <Button
                                        disabled
                                        type="primary"
                                        icon={<BarChartOutlined />}
                                    />
                                </Dropdown>
                            </Tooltip> */}
            </Space>
        </Row>
    );
};

export default TableActions;