import React, { useEffect, useState } from 'react';
import { Space, Tabs, TabsProps } from 'antd';
import { useAppDispatch, useAuth } from '../store/hooks';
import { addShopifyShop, setShopifyShops, ShopifyShop } from '../store/shopifyShopSlice';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import Form from 'antd/es/form';
import ShopifyProductTable from '../components/ShopifyProduct/ShopifyProductTable/ShopifyProductTable';
import ShopifyShopDescription from '../components/ShopifyShop/ShopifyShopDescription';
import ShopifyProductImageTable from '../components/ShopifyProductImage/ShopifyPorductImageTable/ShopifyProductImageTable';
import { PictureOutlined, ProductOutlined } from '@ant-design/icons';



const ShopifyAnalyzeShopPage: React.FC = () => {
    const auth = useAuth()
    const dispatch = useAppDispatch()

    const [form] = Form.useForm<{ url: string; }>()

    const [activeTabKey, setActiveTabKey] = useState<string>("1")


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <span>
                <ProductOutlined /> Products
            </span>,
            children: <ShopifyProductTable openImages={() => setActiveTabKey("2")} />,
        },
        {
            key: '2',
            label: <span>
                <PictureOutlined /> Images
            </span>,
            children: <ShopifyProductImageTable />
        },

    ];


    return (
        <div style={{ padding: '20px' }}>
            <Space size="small" direction='vertical' style={{ width: "100%" }}>
                <ShopifyShopDescription />
                <Tabs onChange={(activeKey) => setActiveTabKey(activeKey)} activeKey={activeTabKey} items={items} />
            </Space>
        </div>
    );
};

export default ShopifyAnalyzeShopPage;