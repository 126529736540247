import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface AnalyzeShopifyProductRequest {
    id: string,
    shopId: string
}

interface AnalyzeShopifyProductResponse {
    id: string;
}

const analyzeShopifyProduct = async (shopId: string, id: string): Promise<AnalyzeShopifyProductResponse> => {
    const analyzeShopifyShop = httpsCallable<AnalyzeShopifyProductRequest, AnalyzeShopifyProductResponse>(functions, 'handle_analyze_shopify_product');
    const result = await analyzeShopifyShop({ shopId, id });
    return result.data;
};


interface AnalyzeShopifyProductsRequest {
    id: string,
}

interface AnalyzeShopifyProductsResponse {
    id: string;
}

const analyzeShopifyProducts = async (id: string): Promise<AnalyzeShopifyProductsResponse> => {
    const analyzeShopifyProducts = httpsCallable<AnalyzeShopifyProductsRequest, AnalyzeShopifyProductsResponse>(functions, 'handle_analyze_shopify_products');
    const result = await analyzeShopifyProducts({ id });
    return result.data;
};





export { analyzeShopifyProduct, analyzeShopifyProducts };