import { Modal, Button, Input } from 'antd';
import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { uploadShopifyProductsContent } from '../../api/uploadShopifyShopProductContent';

const { TextArea } = Input;


interface UploadApprovedProductContentProps {
    open: boolean;
    handleClose: () => void;
}

const UploadApprovedProductContentModal: React.FC<UploadApprovedProductContentProps> = ({ open, handleClose }) => {

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const [loading, setLoading] = useState<boolean>(false)


    const handleUpload = async () => {
        if (selectedShopifyShop) {
            setLoading(true);
            try {
                await uploadShopifyProductsContent(selectedShopifyShop?.id)
            } catch (error) {
                console.error(error)
            }
            setLoading(false); // Show success notification after saving
            handleClose(); // Close modal after saving
        }
    };

    return (
        <Modal
            open={open}
            title={`Upload Approved Products`}
            width={"70vw"}
            height={"90vh"}
            onCancel={handleClose}
            footer={[
                <Button key="cancel" onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleUpload} loading={loading} disabled={loading}>
                    {loading ? "Uploading" : "Upload"}
                </Button>,
            ]}
        >
            {/* TODO: This needs to show some details of what needs to be uploaded */}
            Are you shure, you want to upload all approve products?
        </Modal>
    );
};

export default UploadApprovedProductContentModal;
