import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface AnalyzeShopifyProductImageRequest {
    id: string,
    shopId: string
}

interface AnalyzeShopifyProductImageResponse {
    id: string;
}

const analyzeShopifyProductImage = async (shopId: string, id: string): Promise<AnalyzeShopifyProductImageResponse> => {
    try {
        const analyzeShopifyProductImage = httpsCallable<AnalyzeShopifyProductImageRequest, AnalyzeShopifyProductImageResponse>(functions, 'handle_analyze_shopify_product_image');
        const result = await analyzeShopifyProductImage({ shopId, id });
        return result.data;
    } catch (error) {
        console.error("Error analyzing shopify product image: ", error);
        throw error;
    }
};

interface AnalyzeShopifyProductImagesRequest {
    id: string,
}

interface AnalyzeShopifyProductImagesResponse {
    id: string;
}


const analyzeShopifyProductImages = async (id: string): Promise<AnalyzeShopifyProductImagesResponse> => {
    try {
        const analyzeShopifyProductImages = httpsCallable<AnalyzeShopifyProductImagesRequest, AnalyzeShopifyProductImagesResponse>(functions, 'handle_analyze_shopify_product_images');
        const result = await analyzeShopifyProductImages({ id });
        return result.data;
    } catch (error) {
        console.error("Error analyzing shopify product images: ", error);
        throw error;
    }
};

export { analyzeShopifyProductImage, analyzeShopifyProductImages };