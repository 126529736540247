import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface RecalculateShopifyShopStatsRequest {
    id: string
}

interface RecalculateShopifyShopStatsResponse {
    id: string;
}

const recalculateShopifyShopStats = async (id: string): Promise<RecalculateShopifyShopStatsResponse> => {
    const recalculateShopifyShopStats = httpsCallable<RecalculateShopifyShopStatsRequest, RecalculateShopifyShopStatsResponse>(functions, 'recalculate_shopify_shop_stats');
    const result = await recalculateShopifyShopStats({ id });
    return result.data;
};

export { recalculateShopifyShopStats };