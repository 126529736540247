
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface UploadShopifyProductsContnetRequest {
    id: string,
}



const uploadShopifyProductsContent = async (id: string): Promise<null> => {
    const uploadShopifyProductsContnet = httpsCallable<UploadShopifyProductsContnetRequest, null>(functions, 'upload_shopify_products_content');
    const result = await uploadShopifyProductsContnet({ id });
    return null;
};

export { uploadShopifyProductsContent };