import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector, useAuth } from '../../store/hooks';
import { setSelectedShopifyShop, setShopifyShops, ShopifyShop } from '../../store/shopifyShopSlice';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase-config';


const ShopifyShopSelect: React.FC = () => {
    const dispatch = useAppDispatch()
    const shopifyShops = useAppSelector((state) => state.shopifyShops.shopifyShops)
    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const [selectedValue, setSelectedValue] = useState<string | null>(null)
    const auth = useAuth()

    useEffect(() => {
        // Get the current user's ID
        const userId = auth?.uid;

        if (!userId) {
            console.error("User is not authenticated");
            return;
        }

        console.log("ShopifyShopPage: Get ShopifyShops")

        const q = query(
            collection(db, "shopifyShops"),
            where("userIds", "array-contains", userId),
            where("status", "!=", "hidden")
        )

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedShopifyShops = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as ShopifyShop))
            // Update Redux state with the new data
            dispatch(setShopifyShops(updatedShopifyShops));
        },
            (error) => {
                console.error("Error fetching real-time updates: ", error);
            }
        );

        return () => unsubscribe();
    }, [auth, dispatch])

    useEffect(() => {
        if (shopifyShops && selectedShopifyShop) {
            const updatedSelectedShopifyShop = shopifyShops.find((sp) => sp.id === selectedShopifyShop.id);
            if (updatedSelectedShopifyShop) {
                dispatch(setSelectedShopifyShop(updatedSelectedShopifyShop));
            }
        }
    }, [shopifyShops, dispatch])

    const options = useMemo(() => {
        return shopifyShops.map((shopifyShop) => ({
            value: shopifyShop.id,
            label: shopifyShop.shop,
        }));
    }, [shopifyShops])

    // Set current selectedShopifyProduct or the first one as default as selected Value
    useEffect(() => {
        console.log("called")
        if (selectedShopifyShop) {
            setSelectedValue(selectedShopifyShop.id);
        } else if (shopifyShops.length > 0) {
            dispatch(setSelectedShopifyShop(shopifyShops[0]))
        } else {
            setSelectedValue(null);
        }
    }, [selectedShopifyShop, shopifyShops]);

    const handleSelect = (value: string) => {
        if (value != selectedValue) {
            setSelectedValue(value)
            const selectedShopifyShop = shopifyShops.find((shopifyShop) => shopifyShop.id === value)

            if (selectedShopifyShop) dispatch(setSelectedShopifyShop(selectedShopifyShop))
        }
    }

    return (
        <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a Shop"
            optionFilterProp='label'
            options={options}
            onSelect={handleSelect}
            value={selectedValue}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
        />
    )
}

export default ShopifyShopSelect;