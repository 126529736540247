import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { collection, getDocs, doc, Timestamp, updateDoc, OrderByDirection, getDoc, orderBy, where, startAfter, limit, query } from 'firebase/firestore';
import { db } from '../config/firebase-config';


export enum ShopifyProductImageStatus {
  CREATED = "created",
  ANALYZING = "analyzing",
  ANALYZED = "analyzed",
  FAILED_TO_ANALYZE = "failed to analyze",
  NO_MATCHING_PRODUCT_FOUND = "no matching product found",
  APPROVED = "approved",
  BLOCKED = "blocked",
  UPLOADED = "uploaded"
}

// TODO: Add shopfiy product to shopify image for eventual consistency
export interface ShopifyProductImage {
  // ids
  id: string;
  internalShopifyProductId: string;
  shopifyImageId: string;
  shopifyProductId: number;

  // values
  alt?: string;
  height: number;
  width: number;
  position: number;
  shopifyCreatedAt: Timestamp;
  shopifyUpdatedAt: Timestamp;
  shopifyVariants: string[]; // TODO: Check if this is the correct type
  src: string;

  // generated
  llmAlt?: string;
  llmReason?: string;

  // meta
  status: ShopifyProductImageStatus;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

export interface Pagination {
  page: number;
  pageSize: number;
  lastVisibleDoc: any | null;
  hasMore: boolean
}


interface ShopifyProductImageState {
  shopifyProductImages: ShopifyProductImage[];
  selectedShopifyProductImage: ShopifyProductImage | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  // pagination
  pagination: Pagination;
  activeFilters: Record<string, any>;
  activeSorter: { field: string; order: OrderByDirection } | null;
}

const initialState: ShopifyProductImageState = {
  shopifyProductImages: [],
  selectedShopifyProductImage: null,
  status: 'idle',
  error: null,
  pagination: {
    page: 1,
    pageSize: 20,
    lastVisibleDoc: null,
    hasMore: true
  },
  activeFilters: {},
  activeSorter: null,
};

export const updateShopifyProductImage = createAsyncThunk(
  'shopifyProductImages/updateShopifyProductImage',
  async (updatedShopifyProductImage: Partial<ShopifyProductImage> & { id: string, shopId: string }, { rejectWithValue }) => {
    try {
      const { id, shopId, ...updates } = updatedShopifyProductImage;
      const timestamp = Timestamp.now();

      // Update the document in Firestore
      await updateDoc(doc(db, 'shopifyShops', shopId, 'shopifyProductImages', id), {
        ...updates,
        updatedAt: timestamp,  // Automatically update the updatedAt timestamp
      });

      return { id, ...updates, updatedAt: timestamp };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const refetchShopifyProductImage = createAsyncThunk(
  'shopifyProducts/refetchShopifyProductImage',
  async ({ shopifyShopId, productImageId }: { shopifyShopId: string; productImageId: string }, { rejectWithValue }) => {
    try {
      const docRef = doc(db, 'shopifyShops', shopifyShopId, 'shopifyProductImages', productImageId);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        throw new Error(`Product Image with ID ${productImageId} not found`);
      }

      return {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      } as ShopifyProductImage;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);


export const fetchShopifyProductImages = createAsyncThunk(
  'shopifyProducts/fetchShopifyShopProductImages',
  async (
    { shopifyShopId,
      pageSize,
      lastVisibleDoc,
      activeSorter,
      activeFilters,
      refetch
    }: {
      shopifyShopId: string,
      pageSize: number,
      lastVisibleDoc: any | null,
      activeSorter?: { field: string; order: OrderByDirection } | null
      activeFilters: Record<string, any>
      refetch: boolean
    }, { rejectWithValue }
  ) => {
    try {
      const constraints = [];
      if (activeSorter) {
        constraints.push(orderBy(activeSorter.field, activeSorter.order));
      }

      // Apply filters dynamically
      Object.entries(activeFilters).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          if (key === 'hasAlt') {
            if (value.length === 1) {
              constraints.push(where('hasAlt', value[0] ? '==' : '!=', null));
            }
          } else if (key === 'shopifyTags') {
            constraints.push(where(key, 'array-contains-any', value));
          } else {
            constraints.push(where(key, '==', value[0]));
          }
        }
      });

      // Handle pagination based on direction
      if (lastVisibleDoc) {
        constraints.push(startAfter(lastVisibleDoc));
      }
      constraints.push(limit(pageSize));

      const queryRef = query(collection(db, 'shopifyShops', shopifyShopId, 'shopifyProductImages'), ...constraints);

      const querySnapshot = await getDocs(queryRef);
      const docs = querySnapshot.docs;
      console.log("length", docs.length)

      const lastDoc = docs.length > 0 ? docs[querySnapshot.docs.length - 1] : null;

      return {
        productImages: querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as ShopifyProductImage[],
        lastVisibleDoc: lastDoc,
        hasMore: querySnapshot.docs.length === pageSize,
        activeFilters: activeFilters,
        activeSorter: activeSorter ? activeSorter : null,
        reftech: refetch
      };
    } catch (error: any) {
      console.error("Error in fetchShopifyProducts:", error);
      return rejectWithValue(error.message || 'An unknown error occurred');
    }
  }
);

const shopifyProductImagesSlice = createSlice({
  name: 'shopifyProductImages',
  initialState,
  reducers: {
    setShopifyProductImagePagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    resetShopifyProductImages: (state) => {
      state.shopifyProductImages = [];
      state.pagination = { page: 1, pageSize: 20, lastVisibleDoc: null, hasMore: true };
      state.status = 'idle';
      state.error = null;
      state.activeFilters = {};
      state.activeSorter = null
    },
    setShopifyProductImages: (state, action: PayloadAction<ShopifyProductImage[]>) => {
      state.shopifyProductImages = action.payload;
    },
    setSelectedShopifyProductImage: (state, action: PayloadAction<ShopifyProductImage | null>) => {
      state.selectedShopifyProductImage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopifyProductImages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShopifyProductImages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const filtersChanged = JSON.stringify(state.activeFilters) !== JSON.stringify(action.payload.activeFilters)
        const sorterChanged = JSON.stringify(state.activeSorter) !== JSON.stringify(action.payload.activeSorter)
        if (filtersChanged || sorterChanged || action.payload.reftech) {
          state.shopifyProductImages = action.payload.productImages;
        } else {
          state.shopifyProductImages = [...state.shopifyProductImages, ...action.payload.productImages];
        }
        // state.shopifyProducts = [...state.shopifyProducts, ...action.payload.products];
        state.pagination.lastVisibleDoc = action.payload.hasMore ? action.payload.lastVisibleDoc : state.pagination.lastVisibleDoc;
        state.pagination.hasMore = action.payload.hasMore
        state.activeFilters = action.payload.activeFilters
        state.activeSorter = action.payload.activeSorter
      })
      .addCase(fetchShopifyProductImages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      }).addCase(refetchShopifyProductImage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refetchShopifyProductImage.fulfilled, (state, action) => {
        const updatedProductImage = action.payload;
        const index = state.shopifyProductImages.findIndex((image) => image.id === updatedProductImage.id);

        // Update the product in the state
        if (index !== -1) {
          state.shopifyProductImages[index] = updatedProductImage;
        }

        state.status = 'succeeded';
      })
      .addCase(refetchShopifyProductImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Failed to refetch product image: ${action.error.message}`;
      })
      .addCase(updateShopifyProductImage.pending, (state, action) => {
        const { id, ...updates } = action.meta.arg;
        const index = state.shopifyProductImages.findIndex((image) => image.id === id);

        // Optimistically update the product in the state
        if (index !== -1) {
          state.shopifyProductImages[index] = {
            ...state.shopifyProductImages[index],
            ...updates,
          };
        }
      })
      .addCase(updateShopifyProductImage.fulfilled, (state, action) => {
        const { id } = action.payload;
        const index = state.shopifyProductImages.findIndex((image) => image.id === id);

        // Replace the product with the updated product from the server
        if (index !== -1) {
          state.shopifyProductImages[index] = {
            ...state.shopifyProductImages[index],
            ...action.payload,
          };
        }
      })
      .addCase(updateShopifyProductImage.rejected, (state, action) => {
        const { id, ...updates } = action.meta.arg;
        const index = state.shopifyProductImages.findIndex((image) => image.id === id);

        // Revert the optimistic update if the backend operation fails
        if (index !== -1) {
          state.shopifyProductImages[index] = {
            ...state.shopifyProductImages[index],
            ...updates,
            // status: 'failed to update', // Add a flag to indicate failure
          };
        }

        state.error = `Failed to update Shopify product: ${action.error.message}`;
      });

  },
});

export const { setShopifyProductImages, setSelectedShopifyProductImage, resetShopifyProductImages, setShopifyProductImagePagination } = shopifyProductImagesSlice.actions;
export default shopifyProductImagesSlice.reducer;