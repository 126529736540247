import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { ShopifyProductStatus } from '../../../store/shopifyProductsSlice';
import { Tag, Image, Skeleton } from 'antd';
import { statusColorsProductImage } from '../../../constants/statusColors';
import { ShopifyProductImage, ShopifyProductImageStatus } from '../../../store/shopifyProductImagesSlice';

interface ColumnsProps {
    handleShopifyProductImageModalOpen: (shopifyProductImage: ShopifyProductImage) => void;
}

export const getColumns = ({
    handleShopifyProductImageModalOpen,
}: ColumnsProps): ColumnsType<ShopifyProductImage> => [
        {
            title: 'Image',
            dataIndex: 'src',
            key: 'src',
            width: 60,
            render: (src) => (
                <div style={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                    {src ? (
                        <Image
                            width={40}
                            height={40}
                            src={src}
                            fallback="https://via.placeholder.com/40" // Placeholder image if loading fails
                            style={{ objectFit: 'cover' }}
                        />
                    ) : (
                        <Skeleton.Avatar active size="small" shape="square" />
                    )}
                </div>
            ),
            fixed: 'left'
        },
        {
            title: 'Id',
            dataIndex: 'shopifyImageId',
            key: 'shopifyImageId',
            width: 100,
            render: (shopifyImageId, shopifyProductImage) => <a onClick={() => handleShopifyProductImageModalOpen(shopifyProductImage)}>{shopifyImageId}</a>,
            fixed: 'left',
            sorter: (a, b) => a.status.localeCompare(b.status)
        },
        {
            title: 'Has Alt',
            dataIndex: 'alt',
            key: 'alt',
            render: (bodyHtml, record) => (
                <Tag key={record.id + '_has_desciption'}>{bodyHtml ? "Yes" : "No"}</Tag>
            ),
            filters: [{
                text: 'Yes',
                value: true,
            }, {
                text: 'No',
                value: false,
            }],
            onFilter: (value, record) => !!record.alt === value,
            width: 120
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            width: 100,
            sorter: (a, b) => a.height - b.height, // Numeric sorting
            filters: [
                { text: 'Small (< 100px)', value: 'small' },
                { text: 'Medium (100px - 500px)', value: 'medium' },
                { text: 'Large (> 500px)', value: 'large' },
            ],
            onFilter: (value, record) => {
                if (value === 'small') return record.height < 100;
                if (value === 'medium') return record.height >= 100 && record.height <= 500;
                if (value === 'large') return record.height > 500;
                return false;
            },
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            width: 100,
            sorter: (a, b) => a.width - b.width, // Numeric sorting
            filters: [
                { text: 'Small (< 100px)', value: 'small' },
                { text: 'Medium (100px - 500px)', value: 'medium' },
                { text: 'Large (> 500px)', value: 'large' },
            ],
            onFilter: (value, record) => {
                if (value === 'small') return record.width < 100;
                if (value === 'medium') return record.width >= 100 && record.width <= 500;
                if (value === 'large') return record.width > 500;
                return false;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status), // Alphabetical sorting
            filters: Object.values(ShopifyProductImageStatus).map((status) => ({
                text: status,
                value: status,
            })),
            onFilter: (value, record) => record.status === value,
            render: (status: ShopifyProductImageStatus) => (
                <Tag color={statusColorsProductImage[status]}>
                    {status.charAt(0).toUpperCase() + status.slice(1)} {/* Capitalize */}
                </Tag>
            ),
        },
    ];