import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface SyncShopifyProductsRequest {
    id: string,
}

const syncShopifyProducts = async (id: string): Promise<null> => {
    const syncShopifyProducts = httpsCallable<SyncShopifyProductsRequest, null>(functions, 'sync_shopify_products');

    const temp = await syncShopifyProducts({ id });
    console.log('temp', temp)
    return null;
};

export { syncShopifyProducts };