
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Descriptions, Input, notification, Row, Space, Tag, Tooltip } from "antd";
import { getFlagEmoji } from '../../utils/languageHelpers';
import { EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedShopifyShop, updateShopifyShop } from '../../store/shopifyShopSlice';
import EditShopifyShopDescriptionModal from './EditShopifyShopDescriptionModal';
import { fetchShopifyProducts, ShopifyProduct } from '../../store/shopifyProductsSlice';
import StatusChart from '../Charts/StatusChart';
import { statusColorsProduct, statusColorsProductImage } from '../../constants/statusColors';
import { syncShopifyProducts } from '../../api/syncShopifyProducts';


const { Search } = Input
interface ShopifShopDesciptionProps {
}

// Correctly forward the ref
const ShopifyShopDescription: React.FC<ShopifShopDesciptionProps> = () => {


    const shopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const shopifyProducts = useAppSelector((state) => state.shopifyProducts.shopifyProducts)
    const shopifyProductImages = useAppSelector((state) => state.shopifyProductImages.shopifyProductImages)

    const [keywords, setKeywords] = useState(shopifyShop?.llmKeywords || []);
    const [newKeyword, setNewKeyword] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editKey, setEditKey] = useState<string>('')
    const [editValue, setEditValue] = useState<string>('')
    const [savingKeywords, setSavingKeywords] = useState<boolean>(false)
    const [refreshinfData, setRefreshingData] = useState<boolean>(false)


    useEffect(() => {
        if (shopifyShop?.llmKeywords) {
            setKeywords(shopifyShop.llmKeywords);
        }
    }, [shopifyShop]);

    // const groupByStatus = <T extends { status: string }>(
    //     items: T[]
    // ): { value: number; name: string }[] => {
    //     const statusMap = items.reduce((acc: Record<string, number>, item) => {
    //         acc[item.status] = (acc[item.status] || 0) + 1;
    //         return acc;
    //     }, {});

    //     return Object.entries(statusMap).map(([status, value]) => ({ name: status, value }));
    // };

    // const productBarData = useMemo(() => groupByStatus(shopifyProducts), [shopifyProducts]);
    // const imageBarData = useMemo(() => groupByStatus(shopifyProductImages), [shopifyProductImages]);



    const displatch = useAppDispatch()

    const handleEditClick = (editKey: string, editValue: string | undefined) => {
        if (!editValue) return
        setEditKey(editKey);
        setEditValue(editValue);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setEditKey("");
        setEditValue("");
        setIsModalOpen(false);
    }

    const handleTagClose = (index: number) => {
        if (shopifyShop) {
            setSavingKeywords(true)

            const newKeywords = keywords.filter((_, i) => i !== index);

            console.log('newKeywords', newKeywords)

            displatch(updateShopifyShop({
                id: shopifyShop?.id,
                llmKeywords: newKeywords,
            }))

            setNewKeyword('');
            openSuccessNotification()
            setSavingKeywords(false)
        }
    };

    const openSuccessNotification = () => {
        notification.success({
            message: 'Success',
            description: 'The field has been successfully updated!',
            duration: 3, // duration in seconds
        });
    };

    const handleAddKeyword = () => {
        if (newKeyword && !keywords.includes(newKeyword) && shopifyShop) {
            // setKeywords([...keywords, newKeyword]);
            setSavingKeywords(true)

            const newKeywords = [...keywords, newKeyword];

            console.log('newKeywords', newKeywords)

            displatch(updateShopifyShop({
                id: shopifyShop?.id,
                llmKeywords: newKeywords,
            }))

            setNewKeyword('');
            openSuccessNotification()
            setSavingKeywords(false)
            // You can also update the website state or make a backend call here to persist the change
        }
    };

    const handleRefreshData = async () => {
        console.log('refreshing data')
        if (shopifyShop) {
            console.log('shopify shop')
            setRefreshingData(true)
            try {
                await syncShopifyProducts(shopifyShop.id)
            } catch (error) {
                console.error(error)
            }
            setRefreshingData(false)
        }
    }

    const descriptionItems = useMemo(() => {
        if (shopifyShop) {
            return [
                {
                    key: 1,
                    span: 6,
                    label: 'Shop Status',
                    children: <Tag>{shopifyShop.status}</Tag>,
                },
                {
                    key: 2,
                    span: 6,
                    label: 'Product Status',
                    // children: <StatusChart data={shopifyProducts} colorMap={statusColorsProduct} />,
                    children: ""
                },
                {
                    key: 3,
                    span: 6,
                    label: 'Image Status',
                    // children: < StatusChart data={shopifyProductImages} colorMap={statusColorsProductImage} />
                    children: ""
                },
                {
                    key: 4,
                    span: 6,
                    label: 'Actions',
                    children: <Row justify="end">
                        <Space size="small">
                            <Tooltip title="Refresh Data">
                                <Button
                                    type="default"
                                    icon={<ReloadOutlined />}
                                    loading={refreshinfData}
                                    disabled={refreshinfData}
                                    onClick={handleRefreshData}
                                />
                            </Tooltip>
                        </Space>
                    </Row>
                },
                {
                    key: 5,
                    span: 12,
                    label: (
                        <Space direction="horizontal" size="small" style={{ display: 'flex' }}>
                            Description
                            <Button type="text" onClick={() => handleEditClick('Description', shopifyShop.llmDescription)} style={{ marginLeft: 8 }} icon={<EditOutlined />} />
                        </Space>
                    ),
                    children: shopifyShop.llmDescription,
                },
                {
                    key: 6,
                    span: 12,
                    label: (
                        <Space direction="horizontal" size="small" style={{ display: 'flex' }}>
                            Target Audience
                            <Button type="text" onClick={() => handleEditClick('Target Audience', shopifyShop.llmTargetAudience)} style={{ marginLeft: 8 }} icon={<EditOutlined />} />
                        </Space>
                    ),
                    children: shopifyShop.llmTargetAudience,
                },
                {
                    key: 7,
                    span: 24,
                    label: 'Keywords',
                    children: (
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginBottom: '8px' }}>
                                {keywords.length ? (
                                    keywords.map((keyword: string, index: number) => (
                                        <Tag
                                            key={keyword}
                                            color="blue"
                                            closable={!savingKeywords}
                                            onClose={() => handleTagClose(index)}
                                        >
                                            {keyword}
                                        </Tag>
                                    ))
                                ) : (
                                    <span>No keywords available</span>
                                )}
                                <Search
                                    value={newKeyword}
                                    allowClear
                                    onChange={(e) => setNewKeyword(e.target.value)}
                                    onSearch={handleAddKeyword}
                                    placeholder="Enter a new keyword"
                                    disabled={savingKeywords}
                                    style={{
                                        width: '200px',
                                        marginRight: '8px',
                                    }}
                                    enterButton={
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                        />
                                    }
                                />
                            </div>
                            {/* Input and button to add keywords */}
                        </div>
                    ),
                }
            ]
        }
        return [
            { key: 1, label: 'Shop', children: 'Not Available' },
            { key: 2, label: 'Language', children: 'Not Available' },
            { key: 3, label: 'Description', children: 'Not Available' },
            { key: 4, label: 'Target Audience', children: 'Not Available' },
            { key: 5, label: 'Keywords', children: 'No keywords available' },
        ]
    }, [shopifyShop, keywords, newKeyword, shopifyProducts, shopifyProductImages]);

    return (
        <div>
            <Descriptions
                bordered
                size="small"
                column={24}
                items={descriptionItems}
                labelStyle={{ width: 100, fontSize: 14 }}
                contentStyle={{ fontSize: 14 }}
                layout="vertical"
            />
            <EditShopifyShopDescriptionModal
                open={isModalOpen}
                handleClose={handleModalClose}
                value={editValue}
                fieldToUpdate={editKey}
                shopifyShop={shopifyShop}
            />
        </div>
    )
};


export default ShopifyShopDescription;
