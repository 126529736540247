import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addDoc, collection, getDocs, onSnapshot, query, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import staticMethods from 'antd/es/message';
import { ShopifyProductImageStatus } from './shopifyProductImagesSlice';
import { ShopifyProductStatus } from './shopifyProductsSlice';


enum ShopifyShopStatus {
  CREATED = "created",
  VALIDATED = "validated",
  FAILED_TO_VALIDATE = "failed to validate",
  SCRAPED = "scraped",
  LANGUAGE_DETECTED = "language detected",
  DESCRIPTION_CREATED = "description created",
  METADATA_ANALYZED = "metadata analyzed",
  EXTRACTING = "extracting",
  ANALYZING = "analyzing",
  DONE = "done",
  ERROR = "error",
  HIDDEN = "hidden"
}

export interface ShopifyShopStats {
  productCount: number,
  imageCount: number,
  imageStatusCount: Record<ShopifyProductImageStatus, number>
  productStatusCount: Record<ShopifyProductStatus, number>
}

export interface ShopifyShop {
  // ids
  id: string;
  userIds: [string];
  // metrics
  shop: string;
  status: string;
  productCount?: number;
  iamgeCount?: number;
  llmLanguage?: string;
  llmKeywords?: string[];
  llmTargetAudience?: string;
  llmDescription?: string;
  shopifyTags?: string[];
  shopifyProductTypes?: string[];
  stats?: ShopifyShopStats;
  // meta
  updatedAt: Timestamp;
  createdAt: Timestamp;
}


interface ShopifyShopState {
  shopifyShops: ShopifyShop[];
  selectedShopifyShop: ShopifyShop | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ShopifyShopState = {
  shopifyShops: [],
  selectedShopifyShop: null,
  status: 'idle',
  error: null,
};


export const updateShopifyShop = createAsyncThunk(
  'shopifyShops/updateShopifyShop',
  async (updatedShopifyShop: Partial<ShopifyShop> & { id: string }, { rejectWithValue }) => {
    try {
      const { id, ...updates } = updatedShopifyShop;
      const timestamp = Timestamp.now();

      // Update the document in Firestore
      await updateDoc(doc(db, 'shopifyShops', id), {
        ...updates,
        updatedAt: timestamp,  // Automatically update the updatedAt timestamp
      });

      return { id, ...updates, updatedAt: timestamp };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

// Thunk to add a new website to the Firestore database
export const addShopifyShop = createAsyncThunk(
  'shopifyShops/addShopifyShop',
  async (newWebsite: Omit<ShopifyShop, 'id' | 'createdAt' | 'updatedAt' | 'status'>, { rejectWithValue }) => {
    try {
      // Get the current Firestore Timestamp
      const timestamp = Timestamp.now();

      const shopifyShopWithTimestamps = {
        ...newWebsite,
        status: ShopifyShopStatus.CREATED,
        createdAt: timestamp,
        updatedAt: timestamp,
      }

      console.log('websiteWithTimestamps', shopifyShopWithTimestamps)

      const docRef = await addDoc(collection(db, 'shopifyShops'), shopifyShopWithTimestamps);
      return { id: docRef.id, ...shopifyShopWithTimestamps };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const fetchShopifyShops = createAsyncThunk('shopifyShops/fetchShopifyShops', async () => {
  const querySnapshot = await getDocs(collection(db, 'shopifyShops'));
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  })) as ShopifyShop[];
});

const shopifyShopsSlice = createSlice({
  name: 'shopifyShops',
  initialState,
  reducers: {
    setShopifyShops: (state, action: PayloadAction<ShopifyShop[]>) => {
      state.shopifyShops = action.payload;
    },
    setSelectedShopifyShop: (state, action: PayloadAction<ShopifyShop | null>) => {
      state.selectedShopifyShop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopifyShops.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShopifyShops.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.shopifyShops = action.payload;
      })
      .addCase(fetchShopifyShops.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      }).addCase(updateShopifyShop.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.shopifyShops.findIndex(shopifyShop => shopifyShop.id === action.payload.id);
        if (index !== -1) {
          // Update the website in the state
          state.shopifyShops[index] = { ...state.shopifyShops[index], ...action.payload };
        }
      })
      .addCase(updateShopifyShop.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const { setShopifyShops, setSelectedShopifyShop } = shopifyShopsSlice.actions;
export default shopifyShopsSlice.reducer;