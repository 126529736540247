import React, { useRef, useState } from 'react';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import type { InputRef, TableColumnType } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';

type DataIndex = string | number | (string | number)[];

export const useTableSearch = <T,>() => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<DataIndex>('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm']) => {
    clearFilters();
    setSearchText(''); // Clear the search text
    confirm(); // Trigger immediate filtering (shows all results)
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    customRender?: (text: any, record: T) => React.ReactNode // Optional custom render
  ): TableColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ''} // Safely handle empty selectedKeys
          onChange={(e) => setSelectedKeys([e.target.value])} // Properly update selectedKeys
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm); // Updated to call confirm
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    // onFilter: (value: boolean | React.Key, record: T) => {
    //   const data = Array.isArray(dataIndex)
    //     ? dataIndex.reduce(
    //       (acc, key) => (acc && typeof acc === 'object' ? acc[key] : null),
    //       record as Record<string, any>,
    //     )
    //     : (record as Record<string, any>)[dataIndex];

    //   return data
    //     ? data.toString().toLowerCase().includes(value.toString().toLowerCase())
    //     : false;
    // },
    render: (text, record) => {
      // Apply Highlighter for searched column
      const highlightedText =
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        );

      // Use custom render if provided, fallback to default behavior
      return customRender ? customRender(highlightedText, record) : highlightedText;
    },
  });

  return { getColumnSearchProps };
};
