import { configureStore } from "@reduxjs/toolkit";
import userReducer from './usersSlice'
import websitesReducer from './websitesSlice'
import websiteImagesReducer from './websiteImagesSlice'
import shopifyShopReducer from './shopifyShopSlice'
import shopifyProductReducer from './shopifyProductsSlice'
import shopifyProductImagesReducer from './shopifyProductImagesSlice'

export const store = configureStore({
    reducer: {
        users: userReducer,
        websites: websitesReducer,
        websiteImages: websiteImagesReducer,
        shopifyShops: shopifyShopReducer,
        shopifyProducts: shopifyProductReducer,
        shopifyProductImages: shopifyProductImagesReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch