import React, { useMemo } from "react";
import ReactECharts from "echarts-for-react";
import type { EChartsOption, BarSeriesOption, TooltipComponentOption } from "echarts";
import { ShopifyShopStats } from "../../store/shopifyShopSlice";

// Props type
interface StatusChartProps {
    data: Record<string, number>; // Input data
    height?: number; // Optional height
    colorMap?: { [key: string]: string }; // Color mapping for statuses
}

const StatusChart: React.FC<StatusChartProps> = ({ data, height, colorMap }) => {
    const addColorMap = (
        items: Record<string, number>,
        colorMap?: { [key: string]: string }
    ) => {
        console.log("status Chart data", items);
        return Object.entries(data).map(([status, value]) => ({
            value, // Raw count
            name: status,
            color: colorMap?.[status],
        }));
    };

    // Memoize the processed data
    const seriesData = useMemo(() => addColorMap(data, colorMap), [data, colorMap]);

    // Calculate total count for xAxis max
    const total = Object.values(data).reduce((acc, num) => acc + num, 0);

    // Generate series dynamically
    const series: BarSeriesOption[] = seriesData.map(({ name, value, color }) => ({
        name, // Status name
        type: "bar", // Explicitly specify the type
        stack: "total", // Stack all statuses
        data: [value], // Associate value with the single y-axis category
        itemStyle: {
            color,
        },
        label: {
            show: true,
            position: "inside",
            formatter: "{c}", // Display count
        },
        emphasis: {
            focus: "series",
        },
    }));

    // ECharts options for horizontal stacked bar chart
    const options: EChartsOption = {
        tooltip: {
            trigger: "item",
            formatter: (params) => {
                // Narrowing the type of params to ensure it has the expected properties
                if (
                    typeof params === "object" &&
                    "seriesName" in params &&
                    "value" in params
                ) {
                    const { seriesName, value } = params as { seriesName: string; value: number };
                    return `${seriesName}: ${value}`;
                }
                return "";
            },
        },
        xAxis: {
            type: "value", // Horizontal value axis
            max: total, // Set max to total count
            axisLabel: {
                show: false, // Completely hide the axis labels
            },
            axisTick: {
                show: false, // Hide x-axis ticks
            },
            splitLine: {
                show: false, // Hide grid lines
            },
            axisLine: {
                show: false, // Optionally hide the x-axis line itself for a cleaner look
            },
        },
        yAxis: {
            type: "category", // Vertical single category
            data: ["Status Distribution"], // Single category for the bar
            show: false, // Hide y-axis for a cleaner look
            axisTick: {
                show: false, // Hide x-axis ticks
            },
            splitLine: {
                show: false, // Hide grid lines
            }
        },
        grid: {
            left: "0%", // Adjust the chart layout
            right: "0%",
            top: "20%",
            bottom: "20%",
        },
        series, // Use the typed series
    };

    return <ReactECharts option={options} style={{ height: height ?? 60, width: "100%" }} />;
};

export default StatusChart;
